<template>
  <div>
    <PSection pubg-result-23s3>
      <div class="inner-holder">
        <SectionTitle title="LEADERBOARD" title-ko="기록실" black sm/>
        <div class="contents-holder">
          <div class="result-holder">
            <div class="empty" v-if="!isResult"/>
            <div v-else class="result">
            <PItem tag="h2" class="logo">PUBG LVUP SHOWDOWN</PItem>
            <article>
              <div class="final-list" v-if="showFinalList">
                <FinalMemberTable benefitLegend="2023 PWS Phase 2 시드권을 확보한 팀" :mark-size="5" title="2023 PLS 시즌 3 결선 결과" arena-id="64c86e35cb8cdb0007017bdf" :group-match="groupMatch" finished/>
              </div>
              <PItem tag="h3">2023 PLS 시즌 3 예선 / 본선 결과</PItem>
              <PItem class="week-data">
                <dl v-for="(item, i) in weekData" :key="`week-${i}`">
                  <dt>
                    <span>{{ i + 1 }}주차</span>
                  </dt>
                  <dd class="date" v-html="item.preliminaryDate"></dd>
                  <dd class="link">
                    <button @click="showQualifierResult(i)" :class="{ active: checkQualifierActive(i) }">예선 결과</button>
                  </dd>
                  <dd class="date" v-html="item.finalDate"></dd>
                  <dd class="link">
                    <button @click="showFinalResult(i)" :class="{ active: checkFinalActive(i) }">본선 결과</button>
                  </dd>
                </dl>
              </PItem>
            </article>
          </div>
          </div>
          <div class="prev-leaderboard" :class="{ open }">
            <button class="main-btn" @click="openResult">
              <span>PLS 명예의 전당</span>
            </button>
            <p v-if="!open">클릭하여 이전 시즌 결과를 확인해 보세요.</p>
            <template v-if="open">
              <h3>PLS 명예의 전당</h3>
              <article v-for="item in history" :key="item.title">
                <div class="header">{{ item.title }}</div>
                <div class="body">
                  <table :class="item.cls">
                    <tbody>
                      <tr v-for="(team, idx) in item.rank" :key="team.name">
                        <td class="rank">{{ idx + 1 }}위 팀</td>
                        <td class="team"><img :src="`/img/teams/${team.img}`"> {{ team.name }}</td>
                        <td class="award" :rowspan="team.span || 1" v-if="team.awards">{{ team.awards }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <a :href="`https://lvup.gg/arena/pubg/${item.arena}/bracket`" target="_blank">풀리그 상세 결과 보기 <img src="/img/pages/icon/challenge.svg"></a>
              </article>
            </template>
          </div>
        </div>
      </div>
    </PSection>
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import { historyMove } from 'shared/utils/routerUtils';
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import FinalMemberTable from '@/views/components/pls_2023_3/leagueResult/FinalMemberTable.vue';

export default {
  name: 'PubgResult',
  components: { FinalMemberTable, SectionTitle },
  mixins: [gtag],
  data() {
    return {
      isResult: true,
      showFinalList: true,
      open: false,
      weekData: [
        { preliminaryDate: '8월 21일 <s>(월)</s>', finalDate: '8월 22일 <s>(화)</s>', preliminaryLink: 'https://lvup.gg/arena/64c86dfacb8cdb0007017bac/bracket?stage-id=64e32f922a4ff800077f4709&group=1', finalLink: 'https://lvup.gg/arena/64c86dfacb8cdb0007017bac/bracket?stage-id=64e36a9c92c32b0007e1d9b1&group=1' },
        { preliminaryDate: '8월 28일 <s>(월)</s>', finalDate: '8월 29일 <s>(화)</s>', preliminaryLink: 'https://lvup.gg/arena/64c86e0dcb8cdb0007017bbd/bracket?stage-id=64ec6ba917fd8600071d8ab1&group=1', finalLink: 'https://lvup.gg/arena/64c86e0dcb8cdb0007017bbd/bracket?stage-id=64eca27896c2310007cdd19a&group=1' },
        { preliminaryDate: '9월 4일 <s>(월)</s>', finalDate: '9월 5일 <s>(화)</s>', preliminaryLink: 'https://lvup.gg/arena/64c86e1dcb8cdb0007017bce/bracket?stage-id=64f5a3e5eebdf200076b4048&group=1', finalLink: 'https://lvup.gg/arena/64c86e1dcb8cdb0007017bce/bracket?stage-id=64f5dc4deebdf200076b446d&group=1' },
      ],
      groupMatch: [],
      history: [
        {
          cls: 'prev-2023-2',
          title: '2023 PLS Season 2',
          rank: [
            { img: 'pentagram.png', name: 'AZLA PENTAGRAM', awards: '2023 PSG 2 Qualifier\n 시드권 확보', span: 4 },
            { img: 'stormx_e.png', name: 'StormX e-sports' },
            { img: 'star_balloon.png', name: 'Star Balloon' },
            { img: 'uni_class.png', name: 'UniClass' },
          ],
          arena: '6437c1f50dc2ea00062dbedd',
        },
        {
          cls: 'prev-2023-1',
          title: '2023 PLS Season 1',
          rank: [
            { img: 'fielder.png', name: 'FIELDER eSports', awards: 'PWS 시드권 양도' },
            { img: 'pentagram.png', name: 'PENTAGRAM.', awards: '2023 PWS \nPhase 1 진출', span: 4 },
            { img: 'stormx.png', name: 'StormX' },
            { img: 'orange.png', name: 'Orange' },
            { img: 'gca.png', name: 'GCA Crescendo' },
          ],
          arena: '63a2aa70c274630008bc2eed',
        },
        {
          cls: 'prev-2022-3',
          title: '2022 PLS Season 3',
          rank: [
            { img: 'overwhelm.png', name: 'Overwhelm', awards: '2023 PWS \nPhase 1 진출', span: 2 },
            { img: 'tmi.png', name: 'TMI' },
          ],
          arena: '62fc95ef8684d3000797fcd9',
        },
        {
          cls: 'prev-2022-2',
          title: '2022 PLS Season 2',
          rank: [
            { img: 'bgp.png', name: 'BGP', awards: '2022 PWS \nPhase 2 진출', span: 4 },
            { img: 'no_name.png', name: 'No-Name' },
            { img: 'carpediem.png', name: 'Carpediem' },
            { img: 'hulk_gaming.png', name: 'HulkGaming' },
          ],
          arena: '62382d4e725fba0007f02a19',
        },
        {
          cls: 'prev-2022-1',
          title: '2022 PLS Season 1',
          rank: [
            { img: 'veronica7.png', name: 'Veronica7', awards: '2022 PWS \nPhase 1 진출', span: 2 },
            { img: 'no_name.png', name: 'No-Name' },
          ],
          arena: '61b051dec780ee00076b012e',
        },
      ],
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    openResult() {
      this.trackEvent(`${this.$route.meta.leagueCode}_result_prev`, 'click', `${this.upRouteGameId}`);
      this.open = !this.open;
    },
    checkQualifierActive(idx) {
      return this.weekData[idx].preliminaryLink;
    },
    checkFinalActive(idx) {
      return this.weekData[idx].finalLink;
    },
    showQualifierResult(idx) {
      this.trackEvent(`${this.$route.meta.leagueCode}_result_day${idx + 1}_prematch_01`, 'click', `${this.upRouteGameId}`);
      historyMove(`${this.weekData[idx].preliminaryLink}`);
    },
    showFinalResult(idx) {
      this.trackEvent(`${this.$route.meta.leagueCode}_result_day${idx + 1}_prematch_02`, 'click', `${this.upRouteGameId}`);
      historyMove(`${this.weekData[idx].finalLink}`);
    },
    goArena() {
      this.trackEvent(`${this.$route.meta.leagueCode}_result_season1_Detail`, 'click', `${this.upRouteGameId}`);
      historyMove('https://lvup.gg/arena/pubg/61b051dec780ee00076b012e/bracket?stage-id=61fb969f493ae200070d5fd4&group=1');
    },
    checkedQuery() {
      if (this.$route.query.page && this.$route.query.page === 'pls1') {
        setTimeout(() => {
          const media = this.matchedMediaDevice;
          const target = document.querySelector('.prev-leaderboard');
          this.open = true;
          this.$scroll.scrollMove(target.offsetTop + (media === 'D' ? 100 : 60), 1000);
        }, 200);
      }
    },
  },
  mounted() {
    this.checkedQuery();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[pubg-result-23s3] {
  .inner-holder { .p(40, 20, 60); .font-g;
    .contents-holder {  .bgc(#1e2127); .rel;  .p(100, 0); .mt(18);
      &:before { .cnt; .abs; .bg('@{pubg-result}/bg-img-m.svg'); .wh(100%, 71); .lt(0);}
      &:after {.cnt; .abs; .bg('@{pubg-result}/bg-img-m.svg'); .wh(100%, 71); .lb(0);}
    }
    .result-holder { .wh(100%); .p(0, 10); .font-g; .rel; .mt(18);
      .empty { .wh(100%, 204); .fs(14); .tc; .bg-c; .no-repeat('@{pubg-result}/empty_result_m.svg');.br(4); .items-center; .flex-middle; .c(rgba(255, 255, 255, .5));
      }

      h2 { .mb(72); .wh(212, 74); .fs(0, 0); .mh-c; .no-repeat('@{pubg-result}/bg_result_logo.svg'); .bg-s(100%, 100%); .ani-base;
        .intro-up(1, { .ani-on; });
      }

      h3 { .fs(18, 38); .c(white); .tc; .mb(23); .w(300); .mh-c; .cover('@{pubg-result}/bg_result_sub_title.svg'); .bg-xc; }

      .final-list { .mb(40); }

      .week-data { .rel; .ani-base; .c(white);
        .intro-up(1, { .ani-on; });
        dl { .flex;
          + dl { .mt(6); }
        }
        dt, dd { .flex-center; .h(48); }
        dt { flex: none; .w(40); .medium; .fs(13); .mr(2); background: linear-gradient(101.01deg, #b548e8 0%, #8027aa 100%);
        }
        dd {
          &.date { flex: none; flex-direction: column; .w(44); .fs(11, 15); .ls(-1px); .bgc(rgba(118, 87, 147, .3)); }
          &.link { flex: 1; .bgc(rgba(118, 87, 147, .2));
            + .date { .ml(2); }
            button { .medium; .wh(80, 32); .fs(14); .c(#fff); .br(8); cursor: default; pointer-events: none; .bgc(#5b5c60); .o(.5); box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
              &.active { .pointer; pointer-events: visible; background: linear-gradient(180deg, #b548e8 0%, #8027aa 100%); .o(1); }
            }
          }
        }
      }
      .rank-data { .c(white); .mb(24);
        dl { .flex;
          + dl { .mt(6); }
        }
        dt, dd { .flex-center; .h(48); }
        dt { flex: none; .w(40); .fs(12);
          .bgc(#424d62);
        }
        dd { flex: 1; .ml(2); .medium; .fs(14); .tc; .bgc(rgba(118, 87, 147, .2)); flex-direction: column;
          span, b { .block; .medium; }
          span { .fs(14, 14); .c(#ff89f3); }
          b { .mt(6); .fs(10, 10); .c(#f6ea46); }
        }
        > dl:first-child { .rel; .mt(48);
          &:before { .cnt; .abs; .l(0); .t(-26); .r(0); .h(20);
            .bgc(#424d62);
          }
          dt, dd { .rel;
            &:before { content: attr(data-label); .abs; .l(-2); .t(-26); .r(0); .fs(12, 20); .tc; .-l(#202328, 2); }
          }
          dt:before { .l(0); border-width: 0; }
        }
      }
    }
    .prev-leaderboard { .tc; .mt(40); .p(40, 10); .rel; .mh-c;
      &:before { .cnt; .abs; .lt(50%,0); .t-xc; .wh(300, 1); .bg('@{pubg-result}/bg_divide_new.svg'); }
      &:after { .cnt; .abs; .lb(50%,0); .t-xc; .wh(300, 1); .bg('@{pubg-result}/bg_divide_new.svg'); .hide; }
      &.open {  .bgc(rgba(0, 0, 0, 0.2));
        &:after { .block; }
        .main-btn {
          &:after { .contain('@{pubg-result}/up.svg');}
        }
      }
      .main-btn { .wh(100%, 52); .max-w(295); .rel; .mb(16); .bgc(#d13639); .br(8); .font-g; .flex; .items-center; .justify-center; .gap(8); .pl(10); .mh-c;
        span { .c(white); .semi-bold; .fs(18, 52);}
        &:after { .cnt; .contain('@{pubg-result}/down.svg'); .wh(20);}
      }
      p { .fs(14, 14px); .c(rgba(255, 255, 255, .5)); .font-spoqa; }
      h3 { .fs(20, 38); .c(white); .tc; .mb(23); .w(252); .mh-c; .cover('@{pubg-result}/bg_result_sub_title.svg'); .bg-xc; .mt(70); }
      article { .rel; .max-w(320); .mh-c; .font-g; .mt(40);
        .header { background: linear-gradient(101.01deg, #FF3D00 0%, #7010D0 100%); .c(#fff); .medium; .p(7); }
        .body { .m(4, -4, 0);
          table { .fs(14); border-collapse: separate; .table-fixed; border-spacing: 4px;
            .rank { .fs(12); .c(#fff); .bgc(#424D62); .medium; .w(44); }
            .team { .c(#FF89F3); .p(10,0); .bgc(rgba(118, 87, 147, 0.2)); .fs(10);
              img { .wh(28); .mr(2); }
            }
            .award { .w(116); .fs(12,16); .c(#fff); .bgc(rgba(118, 87, 147, 0.3)); .pre-line; }
          }
        }
        a { .w(240); .bgc(#765793); .flex; .gap(8); .p(8,0); .fs(18); .items-center; .justify-center; .c(#fff); .mh-c; .mt(24); .br(8); .font-g; .bold; }
      }
    }
  }

  @media (@tp-up) {
    .w(710); .mh-c;
  }

  @media (@tl-up) {
    .w(966);
    .inner-holder { .p(60, 0);
      .contents-holder { .p(200, 0); }
      .result-holder { .p(0, 34);
        &:before { .bg('@{pubg-result}/bg-img.svg'); .wh(100%, 118);}
        &:after {.bg('@{pubg-result}/bg-img.svg'); .wh(100%, 118);}
        .empty { .h(320); .fs(16); .no-repeat('@{pubg-result}/empty_result_tl.svg'); }
        .result {
          h2 { .mb(92); .wh(350, 122); }
          article { .max-w(800); .mh-c;
            h3 { .fs(36, 68); .mb(32); .w(640); .cover('@{pubg-result}/tl/bg_result_sub_title.svg'); }
            + article { .mt(52); }
          }
          .final-list { .mb(80); }
          .week-data {
            dl {
              &:nth-child(1) dt span { .bg('@{pubg-intro}/img_week_1.svg'); }
              &:nth-child(2) dt span { .bg('@{pubg-intro}/img_week_2.svg'); }
              &:nth-child(3) dt span { .bg('@{pubg-intro}/img_week_3.svg'); }
              &:nth-child(4) dt span { .bg('@{pubg-intro}/img_week_4.svg'); }
              &:nth-child(5) dt span { .bg('@{pubg-intro}/img_week_5.svg'); }
              + dl { .mt(12); }
            }
            dt, dd { .h(76); }
            dt { flex: none; .w(136); .fs(16, 18); .mr(8);
              span { .ib; .w(85); .pt(40); .no-repeat; .bg-xc; .bg-s(100%, 36); .tc;
                &:before,
                &:after { content: '-'; }
              }
            }
            dd {
              &.date { .w(80); .fs(16, 18); }
              &.link {
                + .date { .ml(8); }
                button { .medium; .wh(180, 48); .fs(20); }
              }
            }
          }
          .rank-data {
            dl + dl { .mt(12); }
            dt, dd { .h(76); }
            dt { .w(136); .fs(26); }
            dd { .ml(8); .fs(26);
              span { .fs(26, 26); }
              b { .mt(8); .fs(14, 16); }
            }
            > dl:first-child { .mt(80);
              &:before { .cnt; .abs; .l(0); .t(-48); .r(0); .h(36); }
              dt, dd { .rel;
                &:before { .l(-8); .t(-48); .fs(20, 36); border-width: 8px; .pl(6);}
              }
              dt:before { border-width: 0; }
            }
          }
        }
      }
      .prev-leaderboard { .p(60, 20); .mt(60); .max-w(840);
        &:before { .wf; }
        &:after { .wf; }
        .main-btn { .max-w(800); .h(72); .mb(24);
          span { .fs(26, 72px); }
          &:before { .wh(28); .r(245);}
        }
        p { .fs(16);}
        h3 { .fs(36, 68); .mb(32); .w(470); .cover('@{pubg-result}/tl/bg_result_sub_title.svg'); }
        article { .max-w(800); .mt(48);
          .header { .fs(20); .p(12); }
          .body { .flex; .fs(14); .gap(8); .m(0, -8);
            table { border-spacing: 8px;
              .rank { .fs(26); .bold; .w(136); }
              .team { .fs(24); .gap(14); .p(20,0);
                img { .wh(36); }
              }
              .award { .w(324); .fs(26,36); }
            }
          }
          a { .w(320); .bgc(#765793); .gap(12); .p(12,0); .fs(24); .mt(44); }
        }
      }
    }

  }

  @media (@ds-up) {
    .w(1200);
    .inner-holder { .p(60, 0, 120);
      .contents-holder {
        .empty { .mh-c; .no-repeat('@{pubg-result}/empty_result_ds.svg'); .max-w(920);}
        .prev-leaderboard { .max-w(920); }
      }
    }
  }
}
</style>
